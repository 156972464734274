<template>
  <QDialog
    v-model="authStore.showLoginModal"
    persistent
  >
    <div class="relative  w-full md:w-sm lg:w-md dark:bg-dark-1 light:bg-light-1">
      <div class="py-5 px-10 flex justify-center dark:bg-dark-3 light:bg-light-3 border-b border-primary">
        <Logo
          class="w-1/2"
          :dark="$q.dark.isActive"
        />
        <QBtn
          v-close-popup
          to="/"
          icon="close"
          flat
          round
          dense
          class="absolute top-2 right-2"
        />
      </div>
      <QForm
        class="py-8 px-10"
        @submit.prevent="authStore.login(form)"
      >
        <div class="flex flex-col gap-4">
          <QInput
            v-model="form.username"
            placeholder="아이디"
            outlined
            class="border border-primary rounded-md overflow-hidden"
            input-class="text-center"
            :bg-color="$q.dark.isActive ? 'dark-3':'light-3'"
          />
          <QInput
            v-model="form.password"
            placeholder="비밀번호"
            outlined
            class="border border-primary rounded-md overflow-hidden"
            input-class="text-center"
            :bg-color="$q.dark.isActive ? 'dark-3':'light-3'"
            type="password"
          />
        </div>
        <div class="mt-5 grid grid-cols-1 gap-4">
          <QBtn
            label="로그인"
            class="dark:bg-primary light:bg-secondaryLight text-white h-12 text-base font-bold"
            unelevated
            type="submit"
            :loading="authStore.logging"
          />
          <QBtn
            label="회원가입"
            class="dark:bg-primary light:bg-secondaryLight text-white h-12 text-base font-bold"
            unelevated
            @click="authStore.showInviteCodeModal = true"
          />
        </div>
        <!--        <div class="pt-5 flex flex-col items-center">-->
        <!--          <div-->
        <!--            class="hover:text-primary"-->
        <!--            @click="authStore.showGuestQuestionModal = true"-->
        <!--          >-->
        <!--            <RouterLink to="">-->
        <!--              비회원문의-->
        <!--            </RouterLink>-->
        <!--          </div>-->
        <!--        </div>-->
      </QForm>
    </div>
  </QDialog>
</template>

<script setup>
import Logo from '@/components/Logo.vue'
import { useAuthStore } from '@/stores/auth.js'
import { reactive } from 'vue'

const authStore = useAuthStore()

const form = reactive({
  username: '',
  password: ''
})
</script>